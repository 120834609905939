<template>
    <div>
        <div>
            <el-select v-model="searchType" slot="prepend" placeholder="选择类型" size="mini">
                <el-option label="微信昵称" :value="1"></el-option>
                <el-option label="UID" :value="2"></el-option>
            </el-select>
            <el-input class="margin-l-sm" v-model="studentName" size="mini" style="width:160px" clearable></el-input>

            <el-button class="margin-l-sm" size="mini" @click="search">筛选</el-button>


            <el-button class="fl-r" type="primary" icon="el-icon-plus" size="mini" @click="showAddStudent">添加学员
            </el-button>
            <div class="clear"></div>
        </div>
        <div class="margin-t-sm">

            <div class="table-content">

                <el-table :data="tableData" v-loading="loading" style="width: 100%;background: #fff;">
                    <el-table-column prop="id" label="id">
                    </el-table-column>
                    <el-table-column prop="user_id" label="uid">
                    </el-table-column>
                    <el-table-column label="头像">
                        <template slot-scope="scope">
                            <el-avatar :src="scope.row.avatar_url"></el-avatar>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="昵称">
                    </el-table-column>
                    <el-table-column prop="expire_at" label="过期时间">
                    </el-table-column>
                    <el-table-column prop="created_at" label="创建时间">
                    </el-table-column>
                    <el-table-column prop="updated_at" label="更新时间">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-link slot="reference" type="primary" @click="editTime(scope.row)">编辑
                            </el-link>-
                            <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                @confirm="deleted(scope.row)">
                                <el-link slot="reference" type="primary">删除</el-link>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div class="table-batch ">
                <span class="fl-r padding-b-sm">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>

        </div>

        <el-dialog title="添加学员" append-to-body :visible.sync="showAddStudentDialog" width="50%">
            <div>
                <div class="padding-sm" v-if="this.form.id === 0">
                    <el-select v-model="searchType" slot="prepend" placeholder="选择类型" size="mini">
                        <el-option label="微信昵称" :value="1"></el-option>
                        <el-option label="UID" :value="2"></el-option>
                    </el-select>

                    <el-select class="margin-l-sm" v-model="studentName" size="mini" clearable filterable remote
                        reserve-keyword @change="selectUser" placeholder="请输入关键词" :remote-method="searchStudent"
                        :loading="loading">
                        <el-option style="height: 40px;line-height: 40px" v-for="item in users" :key="item.id"
                            :label="item.name" :value="item.id">
                            <div
                                style="display: flex;justify-content: start;align-items: center;height: 40px;line-height: 40px">
                                <span style="margin-right: 10px"> {{ item.id }} </span>
                                <el-avatar size="small" :src="item.avatar_url"></el-avatar>
                                <span style="margin-left: 10px;">{{ item.name }}</span>
                            </div>
                        </el-option>
                    </el-select>
                </div>
                <div v-if="JSON.stringify(selectCurrentUser) !== '{}'" style="
					margin-top: 12px;
					display: flex;
					align-items: center;
					padding: 10px;
					border-radius: 4px;
					margin: 4px;
				">
                    <el-avatar size="small" :src="selectCurrentUser.avatar_url"></el-avatar>
                    <span style="margin-left: 4px">{{selectCurrentUser.name}}</span>
                </div>
                <div>
                    <span style="margin-right: 12px;">有效天数</span>
                    <el-input-number v-model="form.days" :min="1" size="mini"></el-input-number>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showAddStudentDialog = false" size="mini">取 消</el-button>
                <el-button type="primary" @click="addStudent" size="mini" :loading="submitBtnLoading">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog title="编辑" append-to-body :visible.sync="dialogVisible" width="30%">
            <span>
                <el-date-picker v-model="timeData" type="datetime" placeholder="选择日期时间" size="mini"
                    value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="timeRedact">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    data() {
        return {
            loading: false,
            tableData: [],
            searchType: null,
            studentName: null,
            bookId: null,
            page: 1,
            pageSize: 10,
            total: 0,
            form: {
                id: 0,
                days: 1,
            },
            users: [],
            notData: null,
            showAddStudentDialog: false,
            submitBtnLoading: false,
            selectCurrentUser: {},
            timeData: null,
            dialogVisible: false,
            userId: null,
        }
    },
    methods: {
        ...mapActions('hearBook', ['ownUserList', 'addOwn', 'delOwn', 'editOwn']),
        ...mapActions('user', ['getUserList']),
        editTime(row) {
            this.dialogVisible = true
            this.userId = row.user_id
        },
        async timeRedact() {
            let form = {
                userId: this.userId,
                bookId: this.bookId,
                expireAt: this.timeData,
            }
            const { data, res_info } = await this.editOwn(form)
            this.dialogVisible = false
            if (res_info != 'ok') return
            this.$message.success('编辑成功')
            this.getList()

        },
        showAddStudent() {
            this.showAddStudentDialog = true
        },
        selectUser(id) {
            this.users.forEach(item => {
                if (id === item.id) {
                    console.log(item);
                    this.selectCurrentUser.name = item.name
                    this.selectCurrentUser.id = item.id
                    this.selectCurrentUser.avatar_url = item.avatar_url
                }
            })
            this.form.userId = id
        },
        searchStudent(v) {
            let searchData = {}
            searchData.page = 1
            if (this.searchType === 1) {
                searchData.name = v
                searchData.pageSize = 1000
            } else {
                searchData.uid = v
                searchData.pageSize = 1000
            }
            console.log(searchData)
            if (v === '') {
                this.$message.error('请输入昵称')
                return
            }
            this.notData = false
            this.getUserList(searchData).then(res => {
                if (res.data.list.length === 0) {
                    this.notData = true
                }
                this.users = res.data.list
            })
        },
        async deleted(row) {
            let form = {
                userId: row.id,
                bookId: this.bookId,
            }
            const { data, res_info } = await this.delOwn(form)
            if (res_info != 'ok') return
            this.$message.success('删除成功')
            this.getList()
        },
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.getList()
        },
        setPage(page) {
            this.page = page
            this.getList()
        },
        search() {
            this.getList()
        },
        async addStudent() {
            let form = {
                ...this.form,
                bookId: this.bookId,
            }
            const { data, res_info } = await this.addOwn(form)
            this.showAddStudentDialog = false
            if (res_info != 'ok') return
            this.$message.success('添加成功')
            this.searchType = null
            this.getList()
        },
        async getList() {
            this.loading = true
            let form = {}
            if (this.searchType !== null) {
                if (this.searchType == 1)
                    form.nickName = this.studentName
                else
                    form.userId = this.studentName
            }
            form.bookId = this.bookId
            const { data } = await this.ownUserList(form)
            this.total = data.total
            console.log(data);
            this.tableData = data.list
            this.loading = false
        },
    },
    mounted() {
        this.bookId = this.$route.params.id
        this.getList()
    },
}
</script>

<style>
.icon-hover {

    cursor: pointer;
    font-size: 14px;
}

.icon-hover:hover:before {
    font-size: 14px;
    content: "\e7a0";
}

.el-tag {
    height: auto;
    line-height: auto;
}

.el-tag .el-icon-close {
    top: 5px;
}
</style>
